import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import colors from "../styles/colors"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import { getCart } from "../services/cart/cart"
import CartWidget from "../components/Cart/CartWidget"

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const Heading = styled.h2`
  text-transform: uppercase;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 30px;
  color: ${colors.royalBlue};
`

const Text = styled.p``

const CartPage = (props) => {
  const cart = getCart()

  console.log(cart)

useEffect(() => {
  // Log a message to the console to confirm the effect runs
  console.log("Effect just ran!");

  // Check if 'window.gtag' exists to make sure the gtag library is loaded
  if (typeof window.gtag !== "undefined") {
    
    // Destructure the items array from the cart object
    // const { items } = cart;

    //get items from cart but don't crash if cart is empty
    let items;
    if (cart && cart.items?.length > 0) {
      items = cart.items;
    } else {
      return;
    }

    // Prepare the gtag data object
    const gtagData = {
      items: items.map(item => {
        // Destructure the properties from the item and its product
        const { product, price, quantity, variant_id, variant } = item;
        const { id, name } = product;

        // If a variant_id is available, append the variant name to the product name
        const itemName = variant_id ? `${name} ${variant.name}` : name;
        const sku = variant_id ? variant.sku : product.sku;
        // Return the gtag item data
        return {
          id: sku,
          name: itemName,
          price,
          quantity,
        };
      }),
    };

    // Send the 'view_cart' event to Google Analytics
    
    window.gtag('event', 'view_cart' );
    //todo: add GA4 event
  }
}, [cart]); // Run the effect when the 'cart' object changes


  const path = props.location.pathname

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title="In Good Company"
        description=""
        fullTitle={true}
        path={path}
      />
      {cart && Object.entries(cart).length !== 0 && cart.items && cart.items.length !== 0 ? (
        <PageContainer>
          <Heading>Shopping cart</Heading>
          <CartWidget />
        </PageContainer>
      ) : (
        <PageContainer>
          <Heading>Shopping cart</Heading>
          <Text>You have nothing in your shopping cart.</Text>
        </PageContainer>
      )}
    </Layout>
  )
}

export default CartPage
