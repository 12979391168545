/* global fbq */

import React, { useContext, useState, Fragment } from "react"
import { CartContext } from "../../context/CartContext"
import styled from "styled-components"
import OnClickButton from "../Core/OnClickButton"
import { removeItem, updateItem, getCart } from "../../services/cart/cart"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { navigate } from "gatsby"
import ELink from "../Core/ExternalLink"
import axios from "axios"
import { useUserContext } from "../../context/UserContext"
import { getUser } from "../../services/user/user"

const ExternalLinkButton = styled(ELink)`
  max-width: 272.25px;
  min-width: 221px;
  @media (max-width: ${breakpoints.md}) {
    width: 18rem;
  }
  text-align: center;
  ${"" /* width: 70%; */}

  margin-top: 25px;
  margin-bottom: 25px;
  display: ${(props) => (props.desktop ? "none" : "block")};
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 0.35em 1.2em;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  cursor: pointer;
  display: inline-block;
  background: #319ad5;
  border-color: #319ad5;
  color: white !important;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.6em;
  ${
    "" /* font-size: 20px;
  line-height: 18px; */
  }

  @media (max-width: ${breakpoints.md}) {
    ${"" /* margin-bottom: 20px; */}
  }
`
const CartContainer = styled.div``

const ItemGrid = styled.div`
  width: 100%;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
`
const ItemGridHeading = styled.h3`
  :first-child {
    text-align: left;
  }
  :nth-child(2) {
    text-align: center;
  }
  :nth-child(3) {
    text-align: right;
  }

  text-transform: uppercase;
  color: ${colors.midGrey};
  font-size: 0.75rem;
  padding-bottom: 1rem;
`

const Item = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
`

const ItemName = styled.p`
  font-size: 1.2rem;

  @media (max-width: ${breakpoints.xs}) {
    font-size: 1rem;
  }
`

const ItemOption = styled.p`
  color: ${colors.midGrey};
  font-size: 1rem;

  @media (max-width: ${breakpoints.xs}) {
    font-size: 0.9rem;
  }
`

const ItemImage = styled.img`
  max-width: 75px;
  margin-left: 34px;
  margin-right: 34px;
  align-self: center;

  @media (max-width: ${breakpoints.md}) {
    max-width: 10vw;
    margin-left: 17px;
    margin-right: 17px;
  }
`

const Placeholder = styled.div`
  width: 75px;
  margin-left: 34px;
  margin-right: 34px;
  align-self: center;

  @media (max-width: ${breakpoints.md}) {
    width: 10vw;
    margin-left: 17px;
    margin-right: 17px;
  }
`

const ControlButton = styled.button`
  border: none;
  background: transparent;
  color: ${colors.midGrey};
  font-size: 1.5rem;
  cursor: pointer;
`

const Quantity = styled.div`
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (max-width: ${breakpoints.xs}) {
    display: flex;
    flex-direction: column;
  }
`

const QuantityText = styled.span`
  padding-left: 1rem;
  padding-right: 1rem;
`

const Price = styled.p`
  text-align: right;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`

const Subtotal = styled.p`
  margin-left: auto;
  width: fit-content;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const ButtonContainer = styled.div`
  margin-left: 2vw;
  width: fit-content;

  @media (max-width: ${breakpoints.md}) {
    ${"" /* width: 100%; */}

    a {
      display: block;
      text-align: center;
    }
  }
`

const ItemContainer = styled.div`
  border-top: 1px solid ${colors.borderGrey};
  border-bottom: 1px solid ${colors.borderGrey};
  display: grid;
  grid-template-columns: 60% 20% 20%;
  align-items: center;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 50% 25% 25%;
  }

  @media (max-width: 375px) {
    grid-template-columns: 55% 20% 25%;
  }
`

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;
  align-items: center;
  width: 100%;
  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 50% 25% 25%;
  }
  @media (max-width: 375px) {
    grid-template-columns: 55% 20% 25%;
  }
`

const CartWidget = () => {
  const { user, setUser } = useUserContext()
  const [cart, setCart] = useContext(CartContext)

  let items = []

  if (cart && cart.items) {
    items = cart.items
  }

  const updateCart = async () => {
    const cartData = await getCart()

    setCart(cartData)
  }

  const handleRemove = async (productId) => {
    await removeItem(productId)
    updateCart()
  }

  const handleIncrement = async (productId, quantity) => {
    await updateItem(productId, quantity + 1)
    updateCart()
  }

  const handleDecrement = async (productId, quantity) => {
    if (quantity > 1) {
      await updateItem(productId, quantity - 1)
    } else {
      await removeItem(productId)
    }
    updateCart()
  }
  const initiateCheckout = async (e) => {
    const cart = getCart();
    let checkoutUrl = "https://eatigc.swell.store/checkout/" + cart.checkout_id;
    let urlWithClientId = checkoutUrl;

    setTimeout(() => {
      navigater(checkoutUrl);
      window.location.href= checkoutUrl;
    }, 2000); //backup option!

    try {
      const gtagData = constructGtagData(cart);
      const oldGtagData = constructOldGtagData(cart);
      sendGtagEvents(gtagData, oldGtagData);
  
      const items = cart.items;
      const totalValue = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const formattedValue = parseFloat(totalValue.toFixed(2));
      const eventId = generateEventId();
  
      sendFbqEvents(formattedValue, items, eventId);
  
      const conversionData = constructConversionData(formattedValue, items, eventId);
      await sendConversionData(conversionData);
  
      const clientId = await getClientId();
      urlWithClientId = `/checkout/${cart.checkout_id}/shipping?_ga=${clientId}`;
      
      navigater(urlWithClientId ?? checkoutUrl);
    } catch (e) {
      console.error(e);
      navigater(checkoutUrl);
    } finally {
      navigater(urlWithClientId ?? checkoutUrl);
    }
    setTimeout(() => {
      window.location.href = urlWithClientId ?? checkoutUrl;
  }, 1000); // Delay by 1 second to give navigater a chance to work
    //this is a backup in case the navigater doesn't work
  };
  const calculateTotalValue = (items) => {
    return items.reduce((acc, item) => acc + item.price * item.quantity, 0)
  }

  const sendConversionData = async (conversionData) => {
    try {
      const conversionURL = "https://eo4h5c5cv0xdqc2.m.pipedream.net"
      const conversionResponse = await axios.post(conversionURL, conversionData)
      console.log(conversionResponse)
      console.log("meta conversion data sent")
    } catch (e) {
      console.error("Error sending meta conversion data: " + e)
    }
  }
  function navigater(url) {
    var link = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
  const generateEventId = () => {
    return Math.floor(Math.random() * 1e16);
  };
  
  const constructGtagData = (cart) => {
    const { items } = cart

    return {
      items: items.map((item) => {
        const { product, price, quantity, variant_id, variant } = item
        const { name } = product
        const itemName = variant_id ? `${name} ${variant.name}` : name

        return {
          item_id: item.variant_id ?? item.product_id,
          item_name: itemName,
          price,
          quantity,
        }
      }),
      currency: "USD",
      value: cart.grand_total,
      send_to: "G-LZ50YRTV2Y",
    }
  }

  const constructOldGtagData = (cart) => {
    const { items } = cart

    return {
      items: items.map((item) => {
        const { product, price, quantity, variant_id, variant } = item
        const { name } = product
        const itemName = variant_id ? `${name} ${variant.name}` : name
        const sku = variant_id ? variant.sku : product.sku

        return {
          id: sku,
          name: itemName,
          price,
          quantity,
        }
      }),
      send_to: "UA-185335499-1",
    }
  }

  const sendGtagEvents = (gtagData, oldGtagData) => {
    if (typeof window.gtag === "undefined") {
      console.log("gtag not defined")
      return
    }

    if (user) {
      window.gtag("set", "user_id", user.id)
    }

    window.gtag("event", "begin_checkout", oldGtagData)
    window.gtag("event", "begin_checkout", gtagData)
  }

  const sendFbqEvents = (formattedValue, items, eventId) => {
    if (typeof fbq !== "function") {
      console.log("fbq not defined")
      return
    }

    fbq(
      "track",
      "InitiateCheckout",
      {
        value: formattedValue,
        currency: "USD",
        content_type: "product",
        num_items: items.length,
        contents: items.map((item) => ({
          id: item.variant_id ?? item.product_id,
          quantity: item.quantity,
        })),
      },
      { eventID: eventId }
    )
  }

  const constructConversionData = (formattedValue, items, eventId) => {
    const event_source_url = window.location.href

    const conversionData = {
      event_source_url,
      event_id: eventId,
      event_name: "InitiateCheckout",
      contents: items.map((item) => ({
        id: item.variant_id ?? item.product_id,
        quantity: item.quantity,
      })),
      value: formattedValue,
      currency: "USD",
    }

    if (user !== null) {
      conversionData.first_name = user.first_name
      conversionData.last_name = user.last_name
      conversionData.email = user.email

      if (user.shipping) {
        conversionData.phone = user.shipping.phone
        conversionData.zip = user.shipping.zip
      }
    }

    return conversionData
  }

  const getClientId = () => {
    return new Promise((resolve) => {
      if (typeof window.gtag === "undefined") {
        console.log("gtag not defined")
        resolve("")
        return
      }

      window.gtag("get", "UA-185335499-1", "client_id", (clientId) => {
        resolve(clientId)
      })
    })
  }

  return (
    <CartContainer>
      <ItemGrid>
        <InfoGrid>
          <ItemGridHeading>Item</ItemGridHeading>
          <ItemGridHeading>Quantity</ItemGridHeading>
          <ItemGridHeading>Price</ItemGridHeading>
        </InfoGrid>
        {items.map((item, index) => {
          return (
            <ItemContainer key={index}>
              <Item>
                <ControlButton onClick={() => handleRemove(item.id)}>
                  ×
                </ControlButton>
                {item.product.images && item.product.images.length !== 0 ? (
                  <ItemImage src={item.product.images[0].file.url} alt="" />
                ) : (
                  <Placeholder />
                )}
                <div>
                  <ItemName>{item.product.name}</ItemName>
                  {item.options &&
                    item.options.map((option, index) => {
                      return (
                        <ItemOption
                          key={index}
                        >{`${option.name}: ${option.value}`}</ItemOption>
                      )
                    })}
                </div>
              </Item>
              <Quantity>
                <ControlButton
                  onClick={() => handleDecrement(item.id, item.quantity)}
                >
                  −
                </ControlButton>
                <QuantityText>{item.quantity}</QuantityText>
                <ControlButton
                  onClick={() => handleIncrement(item.id, item.quantity)}
                >
                  +
                </ControlButton>
              </Quantity>
              <Price>${item.price_total}</Price>
            </ItemContainer>
          )
        })}
      </ItemGrid>
      <Subtotal>Subtotal ${cart.sub_total}</Subtotal>
      <ButtonsContainer>
        <ButtonContainer></ButtonContainer>
        <ButtonContainer>
          {/* <ExternalLinkButton onClick={(e) => alert('hey!')} > Checkout </ExternalLinkButton> */}
          <OnClickButton onClick={(e) => initiateCheckout(e)} blue={true}>
            <span style={{ color: "white" }}>Checkout</span>
          </OnClickButton>
          <h4
            onClick={() => navigate("/shop")}
            style={{
              marginTop: "20px",
              color: colors.royalBlue,
              textDecoration: "underline",
              verticalAlign: "bottom",
              cursor: "pointer",
            }}
          >
            Continue Shopping
          </h4>
        </ButtonContainer>
      </ButtonsContainer>
    </CartContainer>
  )
}

export default CartWidget
